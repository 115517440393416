//all hex of colors from design assets

const colors = {
  Black: "#000000",
  White: "#FFFFFF",
  Merino: "#E6DBCE",
  Merino2: "#E8E1D7",
  DarkBlue: "#01355B",
  DarkGrey: "#303030",
  LightBlue: "#5CC5EF",
  DarkBlack: "#161616",
  DarkGrey2: "#4D4D4F",
  MainBeige: "#E6E1D6",
  Navy: "#183F5E",
  DarkBrown: "#251C15",
  Pink: "#A7564A",
};

module.exports = {
  colors,
};
