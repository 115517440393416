const DataEnglish = {
  body: [
    "2 Hour Yacht Cruise (Basic)",
    "Soft and Hot Drinks",
    "Sound System , DJ and Microphone",
    "Waiter Services",
    "Lighting System",
    "Catering (Per Request)",
    "Photography (Per Request)",
    "Decoration (Per Request)",
  ],
  title: "All Packages Include",
  end: "For inquires, call: 01211140222",
};
module.exports = {
  DataEnglish,
};
