const DataEnglish = {
  body1:
    "We provide corporations, event managers, organizations and delegations the opportunity to hold and host; private meetings/ conferences, group seminars, celebrations and outings on Cairo’s Nile River. Our yacht’s unique architectural designs enable our delightful guests the chance to experience the Nile River like never before.",
  body2:
    "Whether you’re looking forward to host formal or causal events, our yachts interior and exterior designs give you the chance to host private and public events professionally. Both yachts offer decks with indoor and outdoor lounges, enabling guests the option either to undergo; an outdoor relaxing experience which captures an open view of the Nile or a remarkable indoor experience with a guaranteed sense of privacy.",
  body3:
    "All decks come with different angles of the Nile, providing a unique capture of Cairo’s Nile River.",
};
module.exports = {
  DataEnglish,
};
