const DataEnglish = {
  body: [
    "2 Hours Yacht Cruise",
    "Soft and Hot Drinks",
    "Sound System, DJ and Microphone",
    "Waitering Services",
    "Lighting System  ",
    "Zafaa Speed Boat (Per Request)",
    "Kosha (Per Request)",
    "Catering (Per Request)",
    "Photography (Per Request)",
    "Decoration (Per Request)",

  ],
  title: "Every Wedding Package Includes:",
  end: "For inquires, call: 01211140222",
};
module.exports = {
  DataEnglish,
};
