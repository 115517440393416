const DataEnglish = {
  NavTags: [
    "About Us",
    "Yachts Collection",
    "Gallery",
    "Our Services",
    "Additional Services",
    "Contact Us",
  ],
};
module.exports = {
  DataEnglish,
};
