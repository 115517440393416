const DataEnglish = {
  Title: "Welcome",
  TitleBold: "On Board",
  SubTitleBold: "Who",
  SubTitle: "We Are",
  Body: "Launched in 2021, The Nile Anchor aims at providing high-end value by offering exclusive offers and premium packages to create memorable, breathtaking moments on Egypt’s Nile River. Packages are currently available to; individuals, corporations and organizations providing stress-free solutions to those who wish to organize and hold exclusive events whether; corporate or group outing’s, wedding/engagement ceremonies or even celebrations.",
};
module.exports = {
  DataEnglish,
};
