const DataEnglish = {
  Title: "Gallery",
  ServicesTitle: "Our",
  ServicesTitleBold: "Services",
  LibertyBody:
    "Liberty – Liberating one’s self to a moment of happiness. The two-story yacht is designed to comfortably accommodate guests who seek to host large; events, celebrations, marriage ceremonies, wedding parties, corporate outings and private meetings on Cairo’s Nile River. The yacht’s exterior and interior design is exquisitely designed to give our delightful guests the feeling of luxury, with a touch of class following a sensible feeling of “where Europe meets the Nile”.  The yacht aims at defining perfection through its modernized design and selected furniture on Cairo’s Nile River.The upper deck of the yacht comes with a fascinating 360-degree outdoor panoramic view of the Nile with the capacity to comfortably host around 70 passengers. The yacht is equipped with a high-tech sound system (Boss) projecting a rich sound quality and smooth surround sound experience. The main deck offers a fancy lounge at the front of the deck providing a rich, relaxing outdoor experience and remarkable view of the Nile River. The main deck also offers a special indoor dining experience, where guests encounter a feeling of delight following an added taste of luxury. The Liberty cruise boat is most suitable for those who wish to host large; events, celebrations, outings, meetings, and weddings/engagement ceremonies on Cairo’s Nile River. The yacht has the capacity to comfortably host around 150 passengers.",
  BellaBody:
    "Bella – Italian for beauty, an inner reflection of the yacht’s overall look.  Smaller than the liberty yacht in size yet stands-out elegantly as it cruises throughout Cairo’s Nile River. The yacht offers three decks with a classic high-end interior and exterior design with the capacity to cater around 40 passengers. The upper deck comes with tinted windows and a 360-degree panoramic view, giving our delightful guests the chance to privately experience Cairo’s Nile River. The main deck offers an outdoor lounge at the front of the deck, providing a unique/ rich outdoor dining experience with an open view of the Nile. An indoor lounge bar is located next to the main deck, giving passengers easy access to refreshing drinks. The lower deck offers an extensive indoor lounge experience which is suitable for both special gatherings and private meetings. The Bella yacht is exclusively suitable for those who wish to host small; gatherings, celebrations, engagement ceremonies/parties, private meetings and exclusive V.I.P events.   The Bella yacht offers a deluxe experience for onboard passengers through its modern furniture, beautiful architectural design and unique contrast.",
};
module.exports = {
  DataEnglish,
};
