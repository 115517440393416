const DataEnglish = {
  Title: "Our Mission And Vision",
  subtitle1: "Mission ",
  body1:
    "Happiness comes through journeys, experiences and memories. We aim at bringing the letters of joy to our delightful customers through our premier services and unforgettable, luxurious trips across Cairo’s Nile River. ",
  subtitle2: "Vision ",
  body2:
    "To expand our services throughout Egypt’s Nile River by providing a diversified collection of cruise boat’s which would ease the accessibility and affordability to all. ",
};
module.exports = {
  DataEnglish,
};
