const DataEnglish = {
  body1:
    "Whether you’re looking for the perfect place to celebrate your birthday on the Nile or have a family/friends reunion in Cairo, our services are here to meet your expectations. Our yachts   provide the opportunity to hold; celebrations, special moments and gatherings through their latest interior/ exterior designs and premium services. ",
  body2:
    "Our yachts offer relaxing, luxurious trips around Cairo’s Nile River where special moments turn to reality. All yachts come with indoor and outdoor lounges, providing guests the opportunity to host special occasions more freely and exclusively. Our tri-deck yachts provide customers the ability to host exquisite celebrations while capturing various remarkable views of the Nile. ",
  body3:
    "If you’re looking to host a special event or go for a group gathering, our customizable packages are here to fulfill your needs while adding stress-free solutions to your list",
};
module.exports = {
  DataEnglish,
};
