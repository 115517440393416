const DataEnglish = {
  Title: "Book",
  TitleBold: "Now",
  ButtonLabel: ["Request a", "Register to our", "Chat with us on"],
  ButtonAction: ["Free Call-back", "Newsletter", "Whatsapp"],
  FormInput: ["Your Name", "+02", "Your Email"],
  FormSelect: {
    Boats: [ "Liberty", "Bella", "Vida", "Zein"],
    Time: ["Time"],
    "Event Type": ["Wedding", "Corporate", "Individual"],
  },
  CheckBox: "Send me newsletter",
  Button: "Submit",
};
module.exports = {
  DataEnglish,
};
