const DataEnglish = {
  Title: "Collection",
  TitleBold: "Yacht",
  List: ["Gallery", "Book Now"],
  LibertyBody:
    "Liberty – Liberating one’s self to a moment of happiness. The two-story yacht is designed to comfortably accommodate guests who seek to host large; events, celebrations, marriage ceremonies, wedding parties, corporate outings and private meetings on Cairo’s Nile River. The yacht’s exterior and interior design is exquisitely designed to provide our guests with a sense of European luxury on the Nile.",
  BellaBody:
    "Bella – Italian for beauty, an inner reflection of the yacht’s overall look.  Smaller than the liberty yacht in size yet stands-out elegantly as it cruises throughout Cairo’s Nile River. ",
  ReadMore: "Read More",
  Options: ["liberty", "bella", "vida1", "zein"],
};
module.exports = {
  DataEnglish,
};
