const DataEnglish = {
  body1:
    "Whether you’re willing to host any business event private or public, our customizable packages are here to satisfy your needs while adding stress-free solutions to your list",
  body2: "For Business Inquiries and Exclusive Price Offerings",
  body3: "contact us at: marketing@thenileanchor.com",
  body4: "or call (+02) 01211140222",
};
module.exports = {
  DataEnglish,
};
