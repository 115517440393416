const DataArabic = {
  Title: "احجز",
  TitleBold: "الآن",
  ButtonLabel: ["بالقصف العالم", "بالقصف العالم", "القصف العالم"],
  ButtonAction: ["دون أي", "دون أي", "دون أي"],
  FormInput: ["الاسم", "+02", "البريد"],
  FormSelect: {
    Boats: ["Bella", "Liberty"],
    Time: ["وقت"],
    "Event Type": ["افراح", "شركات", "افراد"],
  },
  CheckBox: "  للسيطرة عدد للأسطول.",
  Button: "التالي",
};
module.exports = {
  DataArabic,
};
