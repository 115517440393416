const DataArabic = {
  FooterTags: ["اعمال", "اتصل بنا", "19601"],
  body1: "Nile view club, Dokki",
  body2: "الجيزة مصر",
  body3: "Tel: 01211140222",
  body4: "reservation@thenileanchor.com",
  body5: `© ${new Date().getFullYear()} The Nile Anchor `,
};
module.exports = {
  DataArabic,
};
